<template>
  <div>
    <b-row class="mb-1">
      <b-col md="3">
        <label label-for="busca2">Busca Cliente</label>
        <b-form-group
          id="busca2"
          label-size="sm"
          label-for="filterInput2"
          class="mb-0"
        >
          <b-input-group size="sm" prepend="ID">
            <b-form-input
              id="filterInput2"
              v-model="filtros.id_company"
              type="search"
              debounce="500"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filtros.id_company"
                @click="filtros.id_company = ''"
              >
                Limpar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <label label-for="busca1">Busca NFSE</label>
        <b-form-group
          id="busca1"
          label-size="sm"
          label-for="filterInput1"
          class="mb-0"
        >
          <b-input-group size="sm" prepend="NFSE ID">
            <b-form-input
              id="filterInput1"
              v-model="filtros.nfiscal_id"
              type="search"
              debounce="500"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filtros.nfiscal_id"
                @click="filtros.nfiscal_id = ''"
              >
                Limpar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col md="2">
        <label>Status</label>
        <b-input-group size="sm">
          <b-form-select
            id="tipo"
            v-model="filtros.status"
            :options="status_options"
            text-field="text"
            value-field="value"
            style="height: 32px"
          >
          </b-form-select>
        </b-input-group>
      </b-col>
      <b-col md="2">
        <label>Sistema</label>
        <b-input-group size="sm">
          <b-form-select
            id="tipo"
            v-model="filtros.sistema"
            :options="sistema_options"
            text-field="text"
            value-field="value"
            style="height: 32px"
          >
          </b-form-select>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col md="6">
        <b-form-group
          label="Período"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-datepicker
              id="data_inicial"
              size="sm"
              v-model="filtros.data_inicial"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              close-button
              weekday-header-format="narrow"
              offset="-25"
              dropdown
              hide-header
              label-close-button="Fechar"
              label-reset-button="Apagar Data"
              labelHelp=""
              locale="pt-BR"
              placeholder="Data"
              reset-button
            />
            <b-form-datepicker
              id="data_final"
              size="sm"
              v-model="filtros.data_final"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              close-button
              hide-header
              weekday-header-format="narrow"
              offset="-25"
              dropdown
              label-close-button="Fechar"
              label-reset-button="Apagar Data"
              labelHelp=""
              locale="pt-BR"
              placeholder="Data"
              reset-button
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col class="d-flex align-items-end">
        <b-button block size="sm" style="padding-bottom: 9px; padding-top: 9px" variant="primary" @click="buscarDados"> Buscar </b-button>
      </b-col>
      <b-col class="d-flex align-items-end">
        <b-button block style="padding-bottom: 9px; padding-top: 9px" variant="success" @click="$bvModal.show('modalEmitirNota')"> Emitir NFSE Avulsa </b-button>
      </b-col>
    </b-row>
    <modal-emitir-nota/>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import {
  BIcon,
  BButton,
  BListGroup,
  BListGroupItem,
  VBToggle,
  BFormDatepicker,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BFormGroup,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import ModalEmitirNota from './ModalEmitirNota.vue';

export default {
  directives: { Ripple, "b-toggle": VBToggle },
  components: {
    BIcon,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    vSelect,
    VBToggle,
    BFormDatepicker,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BFormSelect,
    ModalEmitirNota,
  },

  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
      status_options: [
        { value: "", text: "Todos" },
        { value: "processando", text: "Processando" },
        { value: "agendada", text: "Agendada" },
        { value: "autorizada", text: "Autorizada" },
        { value: "negada", text: "Negada" },
        { value: "cancelada", text: "Cancelada" },
        { value: "substituida", text: "Substituída" },
        { value: "erro", text: "Erro" },
      ],
      sistema_options: [
        { value: 1, text: "Sistema 1" },
        { value: 2, text: "Sistema 2" },
      ],
      filtroSelecionado: "os",
    };
  },

  computed: {
    ...mapState({
      filtros: (state) => state.nota_fiscal.filtros,
    }),
  },

  watch: {
    filtros: {
      handler(val) {
        this.$store.commit("nota_fiscal/STORE_FILTROS", val);
      },
      deep: true,
    },
  },

  methods: {
    buscarDados() {
      this.$store.dispatch("nota_fiscal/listaNotas");
    },
  },

  filters: {
    formataData(data) {
      if (!data) return;

      let d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]}`;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.sidebar-left {
  width: 290px;
}
.todo-application .content-area-wrapper .sidebar .todo-sidebar {
  width: 282px !important;
}
</style>
