<template>
  <b-card>
    <filtros-nota-fiscal/>
    <lista-nota-fiscal/>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import FiltrosNotaFiscal from './FiltrosNotaFiscal.vue';
import ListaNotaFiscal from './ListaNotaFiscal.vue';
export default {
 components: {
  BCard,
  FiltrosNotaFiscal,
  ListaNotaFiscal
 }
}
</script>

<style>

</style>