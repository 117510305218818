<template>
  <b-modal
    hide-footer
    size="xl"
    id="modalEmitirNota"
    title="Emitir NFSE Avulsa"
  >
    <b-row class="mb-1">
      <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
        <div
          class="alert-body"
          v-for="(error, index) in getErrors.messageArray"
          :key="index"
        >
          {{ error[0] }}
        </div>

        <div class="alert-body">{{ getErrors.message }}</div>
      </b-alert>
      <b-col md="4">
        <label label-for="busca2">Busca Cliente</label>
        <b-form-group id="busca2" label-for="filterInput2" class="mb-0">
          <b-input-group prepend="ID">
            <b-form-input
              id="filterInput2"
              v-model="id_company"
              type="search"
              debounce="500"
            />
            <b-input-group-append>
              <b-button :disabled="!id_company" @click="id_company = ''">
                Limpar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <h5 class="text-primary">
      <feather-icon icon="UserIcon" class="mb-25" /> Dados do Cliente
    </h5>
    <b-card border-variant="primary">
      <validation-observer ref="dadosClienteValidation">
        <b-row>
          <!-- NOME -->
          <b-col md="5">
            <b-form-group label="Nome" label-for="nome">
              <validation-provider
                #default="{ errors }"
                name="Nome"
                rules="required|max:100"
              >
                <b-form-input
                  id="nome"
                  v-model="form.nome"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- CPF-->
          <b-col md="">
            <b-form-group label="CPF" label-for="cpf">
              <validation-provider
                #default="{ errors }"
                name="CPF"
                rules="min:14"
              >
                <cleave
                  id="cpf"
                  v-model="form.cpf"
                  class="form-control"
                  :raw="false"
                  :options="options.cpf"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- CNPJ-->
          <b-col md="">
            <b-form-group label="CNPJ" label-for="cnpj">
              <validation-provider
                #default="{ errors }"
                name="CNPJ"
                rules="min:18"
              >
                <cleave
                  id="cnpj"
                  v-model="form.cnpj"
                  class="form-control"
                  :raw="false"
                  :options="options.cnpj"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- EMAIL -->
          <b-col md="5">
            <b-form-group label="Email" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="email|max:50"
              >
                <b-form-input
                  id="email"
                  v-model="form.email"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- DDD -->
          <b-col md="1">
            <b-form-group label="DDD" label-for="ddd">
              <validation-provider
                #default="{ errors }"
                name="DDD"
                rules="min:2"
              >
                <b-form-input
                  id="ddd"
                  v-model="form.ddd"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- TELEFONE -->
          <b-col md="">
            <b-form-group label="Telefone" label-for="telefone">
              <validation-provider
                #default="{ errors }"
                name="Telefone"
                rules="min:8"
              >
                <b-form-input
                  id="telefone"
                  v-model="form.telefone"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- DATA NASCIMENTO -->
          <b-col md="">
            <b-form-group label="Data Nascimento" label-for="data_aniversario">
              <validation-provider
                #default="{ errors }"
                name="Data Nascimento"
                rules="min:8"
              >
                <cleave
                  id="data_aniversario"
                  v-model="form.data_aniversario"
                  class="form-control"
                  :raw="false"
                  :options="options.data_aniversario"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-alert variant="warning" v-if="errorsCep" show>
          <p>Endereço não encontrado para o Cep Digitado.</p>
        </b-alert>

        <b-row>
          <!-- CEP -->
          <b-col cols="12" md="4" lg="4">
            <b-form-group label="CEP" label-for="cep">
              <validation-provider
                #default="{ errors }"
                name="CEP"
                rules="min:10"
              >
                <b-input-group>
                  <cleave
                    id="cep"
                    v-model="form.cep"
                    class="form-control"
                    :raw="false"
                    :options="options.cep"
                  />
                  <b-input-group-append>
                    <b-button variant="outline-primary" @click="buscaCep">
                      <b-spinner small v-if="spinnerCep" />
                      <span v-if="spinnerCep">Aguarde...</span>
                      <span v-else>Buscar Endereço</span></b-button
                    >
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- ENDEREÇO -->
          <b-col cols="12" md="5" lg="5">
            <b-form-group label="Endereço" label-for="endereco">
              <validation-provider
                #default="{ errors }"
                name="Endereço"
                rules="max:100"
              >
                <b-form-input
                  id="endereco"
                  v-model="form.endereco"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- NUMERO -->
          <b-col cols="12" md="3" lg="3">
            <b-form-group label="Número" label-for="numero">
              <validation-provider
                #default="{ errors }"
                name="Número"
                rules="max:10"
              >
                <b-form-input
                  id="numero"
                  v-model="form.numero"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- CIDADE -->
          <b-col md="4">
            <b-form-group label="Cidade" label-for="cidade">
              <validation-provider
                #default="{ errors }"
                name="Cidade"
                rules="max:30"
              >
                <b-form-input
                  id="cidade"
                  v-model="form.cidade"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- UF -->
          <b-col cols="12" md="2" lg="2">
            <b-form-group label="UF" label-for="uf">
              <validation-provider
                #default="{ errors }"
                name="Estado"
                rules="max:2"
              >
                <cleave
                  id="uf"
                  v-model="form.estado"
                  class="form-control"
                  :raw="false"
                  :options="options.uf"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- BAIRRO -->
          <b-col md="6">
            <b-form-group label="Bairro" label-for="bairro">
              <validation-provider
                #default="{ errors }"
                name="Bairro"
                rules="max:30"
              >
                <b-form-input
                  id="bairro"
                  v-model="form.bairro"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <b-card border-variant="primary">
      <validation-observer ref="emitirNotaValidation">
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Valor"
              rules="required|between:0,999999"
            >
              <b-form-group label="Valor" label-for="valor">
                <b-input-group prepend="R$">
                  <money
                    id="valor"
                    v-model="valor"
                    v-bind="money"
                    class="form-control"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <b-form-group label="Data Pagamento" label-for="data">
              <b-form-datepicker
                :date-format-options="{
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }"
                close-button
                hide-header
                weekday-header-format="narrow"
                offset="-25"
                dropdown
                label-close-button="Fechar"
                label-reset-button="Apagar Data"
                labelHelp=""
                locale="pt-BR"
                placeholder="Data"
                reset-button
                v-model="data_pagamento"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Referência"
              rules="required|max:250"
            >
              <b-form-group label="Referência" label-for="referencia">
                <b-form-textarea v-model="referencia" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <b-row class="mb-1">
      <b-col>
        <b-button block @click="fecharModal()">Cancelar</b-button>
      </b-col>
      <b-col>
        <b-button
          block
          :disabled="!form.id_company"
          variant="primary"
          @click="salvarDadosCliente()"
          ><b-spinner small v-if="spinnerDadosCliente" />
          <span v-if="spinnerDadosCliente">Aguarde...</span>
          <span v-else>Salvar Dados do Cliente</span></b-button
        >
      </b-col>
      <b-col>
        <b-button block variant="success" @click="emitirNFSE()"
          ><b-spinner small v-if="spinner" />
          <span v-if="spinner">Aguarde...</span>
          <span v-else>Emitir NFSE</span></b-button
        >
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BFormDatepicker,
  BFormTextarea,
  BAlert,
  BSpinner,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { min, max, required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import Cleave from "vue-cleave-component";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BFormDatepicker,
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,
    BAlert,
    Cleave,
    BSpinner,
    ToastificationContent,
  },
  data() {
    return {
      form: {
        bairro: "",
        cep: "",
        cidade: "",
        cnpj: "",
        cpf: "",
        data_aniversario: "",
        ddd: "",
        email: "",
        endereco: "",
        estado: "",
        id: "",
        id_company: "",
        nome: "",
        numero: "",
        telefone: "",
      },
      spinnerCep: false,
      errorsCep: false,
      errors: false,
      options: {
        cpf: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          numericOnly: true,
        },
        cnpj: {
          delimiters: [".", ".", "/", "-"],
          blocks: [2, 3, 3, 4, 2],
          numericOnly: true,
        },
        cep: {
          delimiters: [".", "-"],
          blocks: [2, 3, 3],
          numericOnly: true,
        },
        uf: {
          blocks: [2],
          uppercase: true,
        },
        data_aniversario: {
          delimiters: ["/", "/"],
          blocks: [2, 2, 4],
          numericOnly: true,
        },
      },
      required,
      min,
      max,
      email,
      id_company: "",
      valor: "",
      referencia: "",
      data_pagamento: "",
      spinner: false,
      spinnerDadosCliente: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
    };
  },
  watch: {
    id_company() {
      if (!this.id_company) return this.limpaDados();
      this.buscaDadosCliente();
    },
  },
  methods: {
    buscaDadosCliente() {
      this.$http
        .get("adm/nfse/smart1/" + this.id_company)
        .then((res) => {
          this.form = res.data;
          this.converteDataAniversario(res.data.data_aniversario);
          this.getErrors = false;
        })
        .catch((error) => {
          let res = this.$responseMessages.getMessage(error, null);
          this.getErrors = res;
        });
    },
    converteDataAniversario(data) {
      if (!data) return;
      this.form.data_aniversario = moment(data).format("DD/MM/YYYY");
    },
    salvarDadosCliente() {
      this.$refs.dadosClienteValidation.validate().then((success) => {
        if (success) {
          this.spinnerDadosCliente = true;
          this.errors = false;
          
          this.$http
            .put(`adm/nfse/smart1/${this.form.id_company}`, this.form)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Dados do Cliente salvos com Sucesso!",
                  icon: "InfoIcon",
                  variant: "success",
                },
              });
              this.getErrors = false;
              this.$refs.dadosClienteValidation.reset();
            })
            .catch((error) => {
              let res = this.$responseMessages.getMessage(error, null);
              this.getErrors = res;
            })
            .finally(() => {
              this.spinnerDadosCliente = false;
            });
        }
      });
    },
    emitirNFSE() {
      this.$refs.emitirNotaValidation.validate().then((success) => {
        if (success) {
          this.spinner = true;
          this.errors = false;

          let payload = {
            id_company: this.id_company,
            valor: this.valor,
            data_pagamento: this.data_pagamento,
            referencia: this.referencia
          }
          this.$http
            .post("adm/nfse", payload)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "NFSE Emitida com Sucesso!",
                  icon: "InfoIcon",
                  variant: "success",
                },
              });
              this.getErrors = false;
              this.$refs.emitirNotaValidation.reset();
            })
            .catch((error) => {
              let res = this.$responseMessages.getMessage(error, null);
              this.getErrors = res;
            })
            .finally(() => {
              this.spinner = false;
            });
        }
      });
    },
    buscaCep() {
      let count = this.form.cep.length;
      if (count > 9) {
        this.spinnerCep = true;
        this.$http
          .get(`adm/cep/${this.form.cep}`)
          .then((res) => {
            if (!res.data.error) {
              this.errorsCep = false;

              if (res.data.cep.erro) {
                this.errorsCep = true;
              }
              this.form.endereco = res.data.cep.logradouro;
              this.form.bairro = res.data.cep.bairro;
              this.form.cidade = res.data.cep.localidade;
              this.form.estado = res.data.cep.uf;
            }
          })
          .catch((res) => {
            this.errorsCep = true;
          })
          .finally(() => {
            this.spinnerCep = false;
          });
      }
    },
    fecharModal() {
      this.id_company = "";
      this.$bvModal.hide("modalEmitirNota");
    },
    limpaDados() {
      this.form = {
        bairro: "",
        cep: "",
        cidade: "",
        cnpj: "",
        cpf: "",
        data_aniversario: "",
        ddd: "",
        email: "",
        endereco: "",
        estado: "",
        id: "",
        id_company: "",
        nome: "",
        numero: "",
        telefone: "",
      };
      this.errors = false;
      this.$refs.dadosClienteValidation.reset();
    },
  },
};
</script>

<style>
</style>